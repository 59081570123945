.DomainsPage {
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 1500px;
  height: 100vh;
  padding: 3rem;
  overflow-y: auto;
  position: relative;
  .DomainsPageImageBackground {
    background-color:silver;
    background-image:
    radial-gradient(circle at 100% 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
    radial-gradient(circle at 0    150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
    radial-gradient(circle at 50%  100%, white 10%, silver 10%, silver 23%, white 23%, white 30%, silver 30%, silver 43%, white 43%, white 50%, silver 50%, silver 63%, white 63%, white 71%, transparent 71%, transparent),
    radial-gradient(circle at 100% 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent),
    radial-gradient(circle at 0    50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent);
    background-size: 100px 50px;
  }
  .fr-checklistTitle {
    font-size: 1.5rem;
  }
  .fr-checklistSubtitle {
    font-size: 1rem;
  }
  .fr-progressBarStepText {
    font-size: 1rem;
  }
  .fr-checklistStepLabel {
    font-size: 1rem;
  }
  .fr-checklistStepTitle {
    font-size: 1.5rem;
  }
  .fr-checklistStepSubtitle {
    font-size: 1rem;
  }
  .fr-checklistStepsContainer {
    pointer-events: none;
    cursor: default;
  }
}
