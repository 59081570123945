.MainLayoutCheckList {
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
  90deg,
  hsl(238deg 97% 25%) 0%,
  hsl(232deg 100% 26%) 11%,
  hsl(228deg 100% 27%) 22%,
  hsl(226deg 100% 29%) 33%,
  hsl(223deg 100% 30%) 44%,
  hsl(221deg 100% 31%) 56%,
  hsl(219deg 100% 32%) 67%,
  hsl(217deg 100% 33%) 78%,
  hsl(216deg 100% 35%) 89%,
  hsl(215deg 95% 37%) 100%
);
}